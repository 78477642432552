import React from "react";
import { Formik, Form } from "formik";
import { ApplePaymentOptions, payload, paymentProviderContractForValidation } from "./payment";

function App() {
  return (
    <div className="apple-pay">
      <Formik
        initialValues={payload}
        onSubmit={(values) => handleApplePayRequest(values)}
      >
        {({ isSubmitting }) => (
          <Form className="form">
            <button
              id="btn"
              type="submit"
              disabled={isSubmitting}
              className="apple-pay__btn"
            ></button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

const REQUEST_HEADERS = {
  "Content-Type": "application/json",
};

const canMakePayments =
  window.ApplePaySession && window.ApplePaySession.canMakePayments();

let session;
function handleApplePayRequest(formValues) {
  console.log("INFO - CAN MAKE PAYMENTS", canMakePayments);
  if (canMakePayments) {
    session = new window.ApplePaySession(3, ApplePaymentOptions);
    session.onpaymentauthorized = makeTransaction(formValues);
    session.addEventListener("validatemerchant", validateMerchant);
    session.begin();
    console.log("INFO - BEGIN SESSION...");
  }
}

function validateMerchant(e) {
  console.log("INFO - TRYING TO VALIDATE MERCHANT...");

  fetch(`/validate.php`, {
    method: "POST",
    headers: REQUEST_HEADERS,
    body: JSON.stringify({
      validation_url: e.validationURL,
      domain: window.location.hostname,
      payment_provider_contract: paymentProviderContractForValidation
    }),
  })
    .then((res) => res.json())
    .then((sessionObject) => {
      console.log("INFO - SESSION OBJECT RECEIVED: ", sessionObject);
      session.completeMerchantValidation(sessionObject);
    })
    .catch((err) => console.log("ERROR - MERCHANT VALIDATION: ", err));
}

function makeTransaction(formValues) {
  return (e) => {
    const applePaymentObject = e.payment;
    const trx = {
      ...payload,
      wallet_payload: applePaymentObject.token.paymentData,
    };
    console.log("TRX", JSON.stringify(applePaymentObject.token));
    console.log(
      "INFO - PAYMENT applePaymentObject RECEIVED: ",
      applePaymentObject
    );

    console.log("INFO - MAKING CREATE TRX REQ TO ECOM...");
    fetch(`/wallet.php`, {
      method: "POST",
      headers: REQUEST_HEADERS,
      body: JSON.stringify(trx),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log("INFO - RECEIVED ECOM RESPONSE: ", res);
        if (res.status === 'AUTHORIZED') {
          session.completePayment(0);
        }
      })
      .catch((err) => console.log("ERROR: ", err));
  };
}

export default App;
