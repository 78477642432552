export const ApplePaymentOptions = {
  countryCode: "US",
  currencyCode: "USD",
  merchantCapabilities: ["supports3DS"],
  supportedNetworks: ["visa", "masterCard", "amex", "discover"],
  total: {
    label: "Demo (Card is not charged)",
    type: "final",
    amount: "20.00",
  }
};

export const paymentProviderContractForValidation = "1b8439c2-7016-4026-8807-3657171a6300a";
//payment_provider_contract: "00979c9b-4446-4748-b340-04a5d7c20a8f",
//d5dc07ec-0521-4676-ba75-96be6754eb35
//payment_provider_contract: "abf62ae0-a174-4aa0-87a9-d4b637601126",
export const payload = {
  payment_provider_contract: "1b8439c2-7016-4026-8807-3657171a6300",
  amount: 2000,
  auth_type: "FINAL_AUTH",
  capture_now: false,
  customer_ip: "127.0.0.1",
  merchant_reference: "TEST-ECOM123",
  card_brand: "MASTERCARD",
  shopper_interaction: "ECOMMERCE",
  currency_code: "USD",
  dynamic_descriptor: "abc123",
  threed_authentication: {
    pares_status: "Y",
    cavv: "MTIzNDU2Nzg5MDEyMzQ1Njc4OTA=",
    enrolled: "Y",
    ds_transaction_id: "ea5e8cb3-69c1-4229-b484-2def1be9952c",
    eci_flag: "05",
    threeds_version: "2.1.0",
    error_desc: "Success",
    error_no: "0",
    signature_verification: "Y",
  },
  wallet_type: "APPLE_PAY",
};
